<template lang="pug">
.new-campaign-sab-recommendations
  Layout(@back="back")
    .container.mt-5
      transition(name="fade" mode="out-in")
        .loading-flow.d-flex.justify-content-center.align-items-center.flex-column(
          v-if="isLoading"
          key="loading"
        )
          lottie(filename="wizard_monk")
          om-heading(h2) {{ $t('optimization.sab.loading.heading') }}
          .color-gray-ultra-dark(:style="{ 'font-size': '1.15rem' }") {{ $t('optimization.sab.loading.subHeading') }}
          lottie(:filename="`sab_loader_${getLocale}`")
        .d-flex.mt-5(v-else)
          .left-section
            .top-section
              .favicon-url
                img.site-favicon(:src="faviconUrl")
                .site-url {{ url.domain }}
              .sub-heading.color-gray-ultra-dark(
                v-html="$t('optimization.sab.recommendations.subText', { count })"
              )
            .table-wrapper(ref="tableWrapper")
              .table-section.mt-5
                om-heading.table-title.ml-1.mb-4(h4) {{ $t('optimization.sab.recommendations.tableTitle') }}
                .table-items
                  .table-item(
                    v-for="(heading, id, index) in headings"
                    @click="scrollToVersions(id)"
                    :class="{ selected: selectedId === id }"
                  )
                    om-heading.table-item-number(h6) #
                      | {{ index + 1 }}
                    om-heading.table-item-value(h6) {{ heading.content }}
              .color-gray-ultra-dark.mt-4 {{ $t('optimization.sab.recommendations.tableSubText') }}
          .right-section(ref="scrollContainer" @scroll="checkVisibility" key="right-section")
            .screenshot
              .tab-wrapper(v-if="screenshotUrl")
                Chrome-tab
                span.url {{ url.gotUrl }}
              img(
                ref="screenshot"
                :src="screenshotUrl ? screenshotUrl : require('@/assets/admin/svg/newCampaignFlow/no-preview.svg')"
              )
            .generated-items
              .generated-item(v-for="(heading, id, index) in headings" ref="itemRefs" :id="id")
                .circle
                  span #
                    | {{ index + 1 }}
                .original-content(:class="{ selected: selectedId === id }") {{ heading.content }}
                .alternatives {{ $t('optimization.sab.recommendations.alternativeToTest') }}
                .versions
                  .version(v-for="version in heading.choices")
                    span {{ version }}
      transition(name="fade")
        .new-campaign-footer(v-if="!isLoading")
          .new-campaign-footer-content
            om-button(@click="handleNext" primary) {{ $t('optimization.sab.recommendations.editAndPreview') }}
</template>
<script>
  import { mapGetters } from 'vuex';
  import { nanoid } from 'nanoid';
  import GENERATE_HEADINGS from '@/graphql/GenerateHeadings.gql';
  import { apiBaseUrl } from '@/config/url';
  import { getAccountIdFromCookie, purifyDomain } from '@/util';
  import { lsStore } from '@/helpers/localStorage';
  import urlModsMixin from '@/mixins/urlMods';
  import campaignCreateMixin from '@/mixins/newCampaignFlow/campaignCreate';
  import ADD_DOMAIN from '@/graphql/AddDomain.gql';
  import UPDATE_HEADINGS_CACHE_KEY from '@/graphql/UpdateHeadingsCacheKey.gql';

  export default {
    components: {
      Layout: () => import('@/components/NewCampaignFlow/Layout.vue'),
      ChromeTab: () => import('@/assets/admin/svg/ChromeTab.vue'),
    },
    mixins: [urlModsMixin, campaignCreateMixin],
    data: () => ({
      headings: null,
      screenshotUrl: null,
      count: 0,
      siteInfoId: null,
      selectedId: null,
      screenshotLoading: true,
      windowHeight: window.innerHeight,
      originalUrl: null,
      scrollWithClick: false,
    }),
    apollo: {
      recommendations: {
        client: 'increasedTimeout',
        query: GENERATE_HEADINGS,
        variables() {
          return {
            url: this.$route.query.url,
          };
        },
        update(data) {
          return data.generateHeadings;
        },
        result({
          data: {
            generateHeadings: { success, headings },
          },
        }) {
          if (this.originalUrl) {
            this.updateHeadingCache(this.url.gotUrl, this.originalUrl);
          }

          if (!success) {
            lsStore(`error-${this.$route.query.url}`, '1', false);
            this.$router.go(-1);
            return;
          }

          this.headings = this.convertHeadings(headings);
          this.count = Object.values(headings).length;
        },
      },
    },
    computed: {
      ...mapGetters(['getLocale', 'domains']),
      isLoading() {
        return this.$apollo.loading || this.screenshotLoading;
      },
      faviconUrl() {
        return `https://t0.gstatic.com/faviconV2?client=SOCIAL&type=FAVICON&fallback_opts=TYPE,SIZE,URL&url=${this.$route.query.url}&size=32`;
      },
    },
    watch: {
      isLoading(v) {
        if (!v) {
          document.body.style.height = 'calc(100vh - 84px)';
        }
      },
    },
    created() {
      import(`@/lottie/sab_loader_${this.getLocale}.json`);
    },
    async mounted() {
      this.setCampaignDomain(this.$route.query.url);
      this.screenshotUrl = await this.fetchScreenshot();
      this.siteInfoId = this.$route.params.siteInfoId;

      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize);
      });
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.onResize);
      document.body.style.removeProperty('height');
    },
    methods: {
      async addDomain() {
        await this.$apollo.mutate({
          mutation: ADD_DOMAIN,
          variables: {
            domain: purifyDomain(this.url.domain),
          },
        });
      },
      async fetchScreenshot() {
        this.screenshotLoading = true;
        return new Promise((resolve, reject) => {
          const imgUrl = new URL(`${apiBaseUrl}/screenshot/preview`);
          imgUrl.searchParams.append('url', this.$route.query.url);
          imgUrl.searchParams.append('width', 1920);
          imgUrl.searchParams.append('height', 1080);
          imgUrl.searchParams.append('userId', getAccountIdFromCookie());
          imgUrl.searchParams.append('skipDomainCheck', true);
          imgUrl.searchParams.append('isNewCampaignFlow', true);
          imgUrl.searchParams.append('returnUrl', '1');

          this.$axios
            .get(imgUrl.href)
            .then((response) => {
              const url = response.data?.url;
              if (url) {
                this.originalUrl = this.url.gotUrl;
                this.updateHeadingCache(url, this.url.gotUrl);
                this.setCampaignDomain(url);
                this.addDomain();
                this.setQueryStringParameter('url', url);
              }
              const b64img = response.data?.img;
              if (!b64img) {
                this.screenshotLoading = false;
                return reject(new Error('Cannot make screenshot'));
              }
              this.screenshotLoading = false;
              resolve(`data:image/png;base64,${b64img}`);
            })
            .catch((error) => {
              this.screenshotLoading = false;
              reject(error);
            });
        });
      },
      async handleNext() {
        this.createOrInsertCodeRedirect({
          userId: this.siteInfoId,
          domain: this.savedDomain,
          hasRequestDate: this.hasRequestRecently(this.savedDomain),
          needCacheChanges: true,
          redirectPath: this.url.path,
        });
      },
      onResize() {
        this.windowHeight = window.innerHeight;
      },
      back() {
        this.$router.go(-1);
      },
      checkVisibility() {
        if (this.scrollWithClick) return;
        const container = this.$refs.scrollContainer;
        const offsetX = this.$refs.tableWrapper.getBoundingClientRect().x;
        const offsetHeight = 200;

        this.$refs.itemRefs.forEach((itemRef) => {
          const rect = itemRef.getBoundingClientRect();
          if (rect.top >= 0 && rect.top <= offsetX + offsetHeight) {
            this.setSelectedId(itemRef.id);
          }

          if (container.scrollTop === 0) {
            this.setSelectedId(null);
          }
        });
      },
      scrollToVersions(id) {
        this.scrollWithClick = true;
        const clickedElementId = this.$refs.itemRefs.find((item) => item.id === id);
        clickedElementId.scrollIntoView({ behavior: 'smooth' });
        this.setSelectedId(id);
        setTimeout(() => {
          this.scrollWithClick = false;
        }, 1000);
      },
      setSelectedId(id) {
        this.selectedId = id;
      },
      convertHeadings(headings) {
        const result = {};
        headings.forEach((item) => {
          const randomGeneratedId = nanoid(9);
          result[randomGeneratedId] = {
            choices: item.choices.map((choice) => choice.trim()).filter(Boolean),
            content: item.content,
          };
        });
        return result;
      },
      async updateHeadingCache(currentUrl, previousUrl) {
        await this.$apollo.mutate({
          mutation: UPDATE_HEADINGS_CACHE_KEY,
          variables: { currentUrl, previousUrl },
        });
      },
    },
  };
</script>
<style lang="sass">
  body
    overflow: hidden
</style>
<style lang="sass" scoped>
  @import '@/sass/variables/_colors'

  @media screen and (min-width: 1200px)
    .new-campaign-sab-recommendations
      font-size: 13px
  @media screen and (min-width: 1400px)
    .new-campaign-sab-recommendations
      font-size: 16px

  .new-campaign-sab-recommendations
    height: 100vh
    width: 100%
    position: fixed
    top: 0
    ::v-deep .heading-4
      font-size: 1.25em
    ::v-deep .heading-6
      font-size: 1em
    ::v-deep .container-fluid
      padding: 0
      .campaign-flow-content
        padding: 0
    .container
      display: flex
      justify-content: center
      max-width: 100%
      padding: 0
      .loading-flow
        gap: 16px
      .left-section
        width: 50%
        display: flex
        flex-direction: column
        gap: 1em
        padding: 3em 1em 0 4.525em
        height: 100vh
        overflow-y: scroll
        &::-webkit-scrollbar
          display: none
        .top-section
          transition: all .5s ease-out
        .favicon-url
          display: flex
          align-items: center
          gap: 0.9375em
          .site-favicon
            width: 1.75em
            height: 1.75em
            border-radius: 50%
            border: 2px solid $om-peach-200
          .site-url
            color: $om-gray-700
            font-weight: 700
        .sub-heading
          font-size: 1.25em
        .table-wrapper
          margin-bottom: 40%
          .table-section
            padding: 1.25em
            border-radius: 8px
            background: #fff
            box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.04), 0px 8px 20px 0px rgba(0, 0, 0, 0.10)
            .table-items
              display: flex
              flex-direction: column
              gap: 0.75em
              .table-item
                display: flex
                flex-direction: row
                gap: 0.75em
                padding: 0.5em 0.5em 0.5em 0.75em
                border-radius: 4px
                border: 1px solid #E3E5E8
                background: #fff
                transition: all 0.25s ease
                cursor: pointer
                &.selected
                  background: $om-peach-200
                &-number
                  display: flex
                  align-items: center
                  min-width: 1.5em
      .right-section
        height: 130vh
        width: 50%
        padding-bottom: 60%
        padding-top: 8em
        padding-right: 4.625em
        overflow-y: scroll
        margin-top: -5rem
        .screenshot
          margin-left: 7.75vw
          box-shadow: 0 16px 40px rgba(0,0,0,0.1)
          border-radius: 12px
          border: 1px solid $om-gray-300
          overflow: hidden
          .tab-wrapper
            position: relative
            .url
              position: absolute
              left: 25%
              color: white
              top: calc( 50% - 0.625em )
              font-size: 0.875em
              overflow: hidden
              text-overflow: ellipsis
              max-width: 65%
              white-space: nowrap
              display: inline-block
          img
            width: 100%
            border-bottom-left-radius: 12px
            border-bottom-right-radius: 12px
          svg
            display: inline-block
            width: 100%
        .generated-items
          display: flex
          flex-direction: column
          gap: 2.5em
          margin-top: 7.8125em
          margin-left: 7.75vw
          .generated-item
            scroll-margin-top: 13.75em
            .circle
              display: flex
              flex-direction: column
              justify-content: center
              align-items: center
              width: 4.5em
              height: 4.5em
              border-radius: 100%
              background: $om-peach-200
              color: $om-orange-500
              font-weight: 700
              margin: 1em 0
              span
                font-size: 1.5em
            .original-content
              font-size: 1.25em
              font-weight: 700
              padding: 0.5em 0.5em 0.5em 0em
              color: #000
              transition: all 0.25s ease
              &.selected
                color: $om-orange-500
            .alternatives
              font-size: 0.875em
              color: $om-gray-700
              font-weight: 500
              margin-block: 0.75em
            .versions
              display: flex
              flex-direction: column
              gap: 0.75em
              .version
                background: $om-gray-100
                border-radius: 4px
                padding: 0.5em 0.5em 0.5em 0.75em
                color: $om-gray-700
                font-weight: 700
    .new-campaign-footer
      position: fixed
      height: 5.25em
      left: 0
      bottom: 0
      width: 100%
      background: #fff
      &-content
        display: flex
        justify-content: flex-end
        height: 100%
        box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.04), 0px -4px 10px 0px rgba(0, 0, 0, 0.10)
        padding: 1em 7em
</style>
